import { Translation } from '../interfaces/translation';

export interface Attachment {
    attachmentId: string;
    attachmentTitle: Translation;
    mediaType: string;
    size: number;
    contentHref: string;
    mainContent: boolean;
}
