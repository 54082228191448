import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EnterprisePreferencesService, LoadingService } from '../../../services';
import { StateFn } from "../../../store/StateFn";
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from "ngx-logger";
import { StoreService } from '../../../store/store.service';
import { ActionsStore } from '../../../store/actions-store';
import { StatusForms } from '../../../store/store-data-interface';
import { FormsHelper } from '../../../helper/Forms-helper';
import { NgModel } from '@angular/forms';
import { ApplicationConstants } from '../../../app.constants';

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styles: []
})
export class EmailUpdateComponent implements OnInit, OnDestroy {
  private emailModel: string;
  private subscriptions: Subscription[] = [];


  @ViewChild('email') email: NgModel;

  @Input()
  accessReadOnly:boolean;

  editMode = false;

  @Input()
  submitAction:Observable<boolean>;
  @Output()
  actionEmitter:EventEmitter<boolean> = new EventEmitter<boolean>();
  
  statusForm: StatusForms;
  handlingsError: {
    status: StatusForms, errorOBj: {
      typeOfError: string;
      args?: [string];
    }};
 
  StatusFormsEnum:any;

  constructor(private logger: NGXLogger, private enterprisePreferencesService: EnterprisePreferencesService,
     private _route: ActivatedRoute, private stateFn: StateFn,
    private _loadingService: LoadingService, private storeService: StoreService, private _actionsStore: ActionsStore, public appConstant:ApplicationConstants) {
    this.StatusFormsEnum = StatusForms;
//    this.editMode = false;
  }

  ngOnInit() {
    this.statusForm = null;
    this.submitAction?.subscribe((isValidate:boolean) => {
      if(isValidate) {
        this.onSubmit().then(result => {
          this.actionEmitter.emit(result);
        });
        
      }
    })
    if (this.stateFn.isAccessTokenValid()) {
      this.subscriptions.push(this.storeService.store.subscribe(s => {
        this.editMode = this.stateFn.getDataForms().isModeUpdateMail;
        if (s.userSession.user !== null && this.emailModel == null) {
          this.emailModel = s.userSession.user.email;
        }
      }));

      this._route.queryParams.subscribe(params => {
        if(params.modeUpdateMail != undefined && params.modeUpdateMail || this.editMode){
          this._actionsStore.updateModeMail(true);
        }

      });

    }
  }

  onEditMode(){
    this._actionsStore.updateModeMail(!this.editMode);
    window.history.pushState(null, "", location.pathname + "?modeUpdateMail=" + this.editMode);
  }

  onSubmit():Promise<boolean> {
    let result = Promise.resolve(true);
    if (this.stateFn.isAccessTokenValid()) {
      if (this.storeService.store.getValue().userSession.user.email !== this.emailModel) {
        this._loadingService.start({serviceRunStart:"submit email-update",delayedStart: true});
       return this.enterprisePreferencesService.updateEnterprisePreferences(this.emailModel).then(resp => {
          this._actionsStore.updateEmail(this.emailModel, null);
          window.history.pushState(null, "", location.pathname);
          return StatusForms.success;
        }).catch(error => {
          this.handlingsError= FormsHelper.handleSendErrorForms(error, StatusForms.error);
          // TODO PROPER ERROR HANDLING
          this.logger.error('Error update Mail', error);
          if(this.handlingsError.status == StatusForms.failed){
            this.emailModel = this.storeService.store.getValue().userSession.user.email;
          }
          
          this.email.control.setErrors({"pattern":true});
          result = Promise.resolve(false);

          return this.handlingsError.status;
        }).then(
          resp => {
            this._loadingService.stop('Email Update Component');
            this._actionsStore.updateStatusMailForms((resp));
            
            this.statusForm = null;
            return result;
          }
        );
      }else{
        return result;
      }
    }else{
      return result;
    }

    
  }

  onCancel() {
    if (this.stateFn.isAccessTokenValid()) {
      this.emailModel = this.storeService.store.getValue().userSession.user.email;
    }
    this._actionsStore.updateModeMail(!this.editMode);
    window.history.pushState(null, "", location.pathname);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }



}
