import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnterprisePreferencesService, LoadingService } from '../../services';
import { Subject, Subscription } from 'rxjs';
import { StateFn } from "../../store/StateFn";
import { HandleExceptionService } from '../../error/handle-exception-service.service';
import { AuthenticateException } from '../../error/CommonException';
import { StoreService } from '../../store/store.service';


@Component({
  selector: 'app-management',
  templateUrl: './management.component.html'

})
export class ManagementComponent implements OnInit, OnDestroy {

  hidden = false;
  numberOfAdminPartitionRights = 0;


  optinAuthorized: boolean;
  private subscriptions: Subscription[] = [];
  isMobile: boolean;
  private _hasEmail: Subject<boolean> = new Subject<boolean>();
  storeIsLoaded: boolean;
  storePartitionisLoaded: boolean;
  isAdmin: boolean;
  partitionPolicyAccepted: boolean;
  docConsumerPolicyAccepted: boolean;


  constructor(public _translateService: TranslateService, private _stateFn: StateFn,
    private _loadingService: LoadingService, private storeService: StoreService,
    private enterprisePreferencesService: EnterprisePreferencesService, private _handleException: HandleExceptionService) {
    this.storeIsLoaded = false;
    this.storePartitionisLoaded = false;
    this._loadingService.start({ serviceRunStart: "Management constructor", delayedStart: false });
    this.optinAuthorized = null;
    this.isMobile = false;
  }

  onAccept() {
    this.enterprisePreferencesService.acceptOptin();
  }


  ngOnInit() {

    if (this._stateFn.isAccessTokenValid()) {

      this.subscriptions.push(this.storeService.store.subscribe(s => {

        this.isMobile = s.isMobile;
        if (s.userSession.user !== null) {

          if (s.userSession.user.exclusivelyEbox !== null) {
            this.optinAuthorized = s.userSession.user.exclusivelyEbox;
          }
          this.isAdmin = s.userSession.isAdmin;
          this.partitionPolicyAccepted = s.userSession.user.hasAcceptedPartitionSelfServicePolicy;
          this.docConsumerPolicyAccepted = s.userSession.user.hasAcceptedDocConsumerPolicy;
          if (s.userSession.user.email !== null) {
            this._hasEmail.next(true);
          }
          this.storePartitionisLoaded = this._stateFn.getAllPartitions() ? this._stateFn.getAllPartitions().length != 0 : false;
          if (this.isEmailSet() && this.optinAuthorized !== null) {
            this.storeIsLoaded = true;
            this._loadingService.stop('ManagementComponent init state');
            this.numberOfAdminPartitionRights = this._stateFn.getNumberOfPartitionAdmin();
          } else if (!s.subsystems.preferences) {
            this.storeIsLoaded = false;
            this._loadingService.stop('ManagementComponent aborted state');
            this.numberOfAdminPartitionRights = this._stateFn.getNumberOfPartitionAdmin();
          }

        }
      }));
    } else {
      const exception: AuthenticateException = new AuthenticateException();
      exception.error = 'minimumAuthentication';
      this._handleException.handlerError(exception); this._loadingService.stop("ManagementComponent init error");
    }

  }

  isAccessReadOnly() {
    return this._stateFn.isAccessReadOnly();
  }

  isAccessAdmin() {
    return this._stateFn.isAccessAdmin();

  }
  isEmailSet() {
    return this._hasEmail.asObservable();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }



}
