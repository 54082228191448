export interface EnterpriseUser {
    ssin?: string;
    firstName: string;
    lastName: string;
    enterpriseName: string;
    cbeNumber?: string;
    email: string | null;
    exclusivelyEbox: boolean | null;
    firstConnectionDate :Date | null;
    lastReminderDate: Date | null;
    hasAcceptedPartitionSelfServicePolicy: boolean;
    hasAcceptedDocConsumerPolicy: boolean;
}

export function  uniformizeCbeNumber(cbeNumber:string): string{
    return  (cbeNumber.length == 9) ? "0" + cbeNumber : cbeNumber;
}
