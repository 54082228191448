export interface Ebox {

    numberOfMessages: number;
    numberOfUnreadMessages: number;
    lastReceiptDate: Date;
    lastConsultationDate: Date;
    eboxSize: number;
    exclusivelyEbox: boolean;

}
