<p *ngIf="!editMode" class="mb-0">
    {{ appConstant.isNullOrUndefined(emailModel) ? ('management.optin.email.required'
    | translate) : emailModel }}
    <a class="ps-1" href="javascript:void(0)" (click)="onEditMode()"
        [title]="'management.optin.edit' | translate" *ngIf="!accessReadOnly">
        <span class="visually-hidden">{{'management.optin.edit' | translate}}</span>
        <i [class]="'fas fa-pencil-alt' + (short ? ' text-white' : '')"
            aria-hidden="true"></i>
    </a>
</p>
<form *ngIf="editMode" class="form-inline w-100" name="mailForm"
    (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div
        class="form-group mb-2 me-3 {{short ? 'col-7' : 'col-12 col-sm-8'}} ps-0 pe-0">
        <label class="visually-hidden" *ngIf="submitAction ==undefined"
            for="email">{{'management.optin.sub-title-email' | translate}}</label>
        <label for="email" *ngIf="submitAction !=undefined"
            class="justify-content-start">{{'optinFlow.email.label' |
            translate}}</label>
        <input id="email" type="text" class="form-control w-100"
            [(ngModel)]="emailModel" name="email" #email="ngModel"
            [ngClass]="{ 'is-invalid': !f.pristine && email.invalid }" required
            pattern="{{emailPattern}}">
        <div *ngIf="!f.pristine && email.invalid" class="invalid-feedback"
            role="alert">
            <span *ngIf="email.errors.required">{{'management.optin.email.required'
                | translate}}</span>
            <span *ngIf="email.errors.pattern">{{'management.optin.email.invalid'
                | translate}}</span>
        </div>
    </div>
    <div class="button groups">
        <ng-container *ngIf="!short">
            <button class="btn btn-primary mb-2"
                [disabled]="f.pristine || f.invalid" type="submit"
                title="{{'management.optin.confirm' | translate}}">{{'management.optin.confirm'
                | translate}}</button>
            <button class="btn btn-link mb-2" type="button" (click)="onCancel()"
                title="{{'management.optin.cancel' | translate}}">{{'management.optin.cancel'
                | translate}}</button>
        </ng-container>
        <ng-container *ngIf="short">
            <button class="btn ps-0 pe-0 mb-2 bg-transparent"
                [style.display]="f.pristine || f.invalid ? 'none' : 'inline-block'"
                type="submit" title="{{'management.optin.confirm' | translate}}">
                <i class="fas fa-check text-success fa-lg" aria-hidden="true"></i>
            </button>
            <a class="ps-1 mb-2" href="javascript:void(0)" (click)="onCancel()"
                [title]="'management.optin.cancel' | translate">
                <span class="visually-hidden">{{'management.optin.cancel' |
                    translate}}</span>
                <i class="fas fa-times text-danger fa-lg" aria-hidden="true"></i>
            </a>
        </ng-container>
    </div>
</form>